import { useParams } from "react-router-dom";
import { tokenToString } from "typescript";
import { User } from "../types/user";

const localStorageKey = "ps-user";
const localStorageKey1 = "PS-Reminder";
const localStorageKey2 = "PS-TabIndex";

function getToken() {
  const userStr = window.localStorage.getItem(localStorageKey);
  if (userStr) {
    const user: User = JSON.parse(userStr);
    return user.token;
  }
  return null;
}

function getStoredUser() {
  const userStr = window.localStorage.getItem(localStorageKey);
  if (userStr) {
    const user: User = JSON.parse(userStr);
    return user;
  }
  return null;
}

function handleUserResponse({ user }: { user: User }) {
  window.localStorage.setItem(localStorageKey, JSON.stringify(user));
  return user;
}

function login({ username, password }: { username: string; password: string }) {
  return client("signin", { username: username, password }).then(
    handleUserResponse
  );
}

function forgetPassword(email: string) {
  return client("forget-password", { email });
}

function resetPassword(payload: { password: string; resetKey: string }) {
  return client("reset-password/finish", payload);
}

function register(payload: { password: string; token: string }) {
  return client("register/:token", payload).then(handleUserResponse);
}

async function logout() {
  window.localStorage.removeItem(localStorageKey);
  window.localStorage.removeItem(localStorageKey1);
}

async function client(endpoint: string, data: unknown) {
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" }
  };
  return window
    .fetch(`/api/${endpoint}`, config)
    .then(async response => {
      // For reason unknown acction confirm api does not return json response
      if (endpoint === "account/confirm") {
        if (response.ok) {
          return response.body;
        } else {
          return Promise.reject(response);
        }
      }

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    })
    .then(error => {
      return error;
    });
}

export {
  getToken,
  getStoredUser,
  login,
  forgetPassword,
  resetPassword,
  register,
  logout,
  localStorageKey
};
