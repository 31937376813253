import React from "react";
import { useQueryClient } from "react-query";
import * as auth from "./auth-api";
import { client } from "./api-client";
import { useAsync } from "./hooks";
import { FullPageErrorFallback } from "../components/lib";
import { getToken, getStoredUser } from "./auth-api";
import { Link, RouterProps, useParams, Redirect } from "react-router-dom";
import { User } from "../types";

async function bootstrapAppData() {
  const user = getStoredUser();
  return user;
}

export type LoginProps = { email: string; password: string };
export type RegisterProps = { password: string; token: string };

export interface AuthContextProps {
  login?: (user: LoginProps) => Promise<void>;
  logout?: () => Promise<void>;
  register?: (user: RegisterProps) => Promise<void>;
  data?: User;
}

const AuthContext = React.createContext<AuthContextProps>({});
AuthContext.displayName = "AuthContext";

interface AuthProviderProps {
  children: JSX.Element;
}
function AuthProvider(props: AuthProviderProps) {
  const token = getToken();

  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData
  } = useAsync({});

  const { queryCache }: any = useQueryClient();

  React.useEffect(() => {
    const appDataPromise = bootstrapAppData();

    run(appDataPromise);
  }, [run]);

  const login = React.useCallback(
    form => {
      return auth
        .login(form)
        .then(async (user: User) => {
          return user;
        })

        .then(user => {
          setData(user);
        });
    },
    [setData]
  );

  const register = React.useCallback(
    form => {
      return auth
        .register(form)
        .then(async (user: User) => {
          return user;
        })
        .then(user => {
          setData(user);
        });
    },
    [setData]
  );

  const logout = React.useCallback(() => {
    queryCache.clear();
    setData(null);
    return auth.logout();
  }, [setData, queryCache]);

  const value = React.useMemo(
    () => ({ data, login, logout, register, token }),
    [login, logout, register, token, data]
  );

  if (isLoading || isIdle) {
    return <div />;
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth(): AuthContextProps {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useClient() {
  const { data } = useAuth();
  const token = data?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
}

export { AuthProvider, useAuth, useClient };
